<template>
  <v-card
    class="d-flex flex-column"
    min-width="200"
    :loading="isLoadingImage"
    style="flex: 1; height: fit-content; width: 100%"
    elevation="0"
    :style="{borderBottom: !isLoadingPerson && !isBeingMonitored ?
               `8px solid red` : '',
             'pointer-events': isASharedPersonProfile ? 'none' : 'auto'}"
    outlined
    @click="seeProfile"
  >
    <div
      v-if="isASharedPersonProfile && !isLoadingImage"
      class="d-flex flex-column justify-center align-center ma-4"
      style="height: 350px;"
    >
      <v-icon
        size="48px"
        color="neutral"
      >
        {{ icons.accountAlert }}
      </v-icon>
      <div class="text-body-5 neutral--text">
        {{ $t('deconve.deconveDatabaseProfile') }}
      </div>
    </div>
    <div
      v-else
      class="d-flex flex-column ma-4"
      style="height: 350px"
    >
      <div
        class="d-flex flex mx-0"
        style="flex: 1; overflow: hidden; border-radius: 4px"
      >
        <v-img
          class="d-flex flex-column rounded"
          width="100%"
          aspect-ratio="1/1"
          :src="profileImage"
        />
      </div>
      <div
        class="d-flex flex-column mt-2"
        style="display: flex; flex: 0; justify-content: center;"
      >
        <v-tooltip
          bottom
          left
          open-delay="600"
          open-on-hover
        >
          <template v-slot:activator="{ on, attrs }">
            <strong
              class="text-truncate subtitle-1 text-sm-h6 font-weight-bold textBlack--text"
              v-bind="attrs"
              v-on="on"
            >
              {{ profileName }}
            </strong>
          </template>
          <span>{{ profileName }}</span>
        </v-tooltip>
        <div class="d-flex flex-wrap mb-4">
          <tag
            v-for="tag in previewTags"
            :key="tag.id"
            class="pa-1"
            small
            :tag-id="tag.id"
          />
          <div
            v-if="tags.length > 2"
            class="d-flex flex-column ml-2"
            style="flex: 1"
          >
            <v-spacer />
            <square-button
              icon-name="mdi-tag-multiple-outline"
              outlined
              color="transparentBackground"
              style="margin-top: auto; z-index: 2"
              icon-color="neutral"
            >
              <v-card max-width="300">
                <v-card-title>
                  {{ $t('deconve.tags') }}
                </v-card-title>
                <v-card-text>
                  <div class="d-flex flex-wrap mb-4">
                    <tag
                      v-for="tag in tags"
                      :key="tag.id"
                      class="pa-1"
                      :tag-id="tag.id"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </square-button>
          </div>
        </div>
      </div>
      <div class="d-flex justify-space-between">
        <div class="d-flex align-center">
          <v-icon
            color="neutral"
            class="mr-1"
            :size="$vuetify.breakpoint.mobile? 16: 20"
          >
            {{ icons.calendarRange }}
          </v-icon>
          <span
            v-if="createdAt"
            class="caption text-sm-body-2 neutral--text"
          >
            {{ createdAt | moment('DD/MM/YYYY') }}
          </span>
        </div>
        <v-tooltip
          v-if="!isLoadingModelInfo && !isBeingMonitored"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              color="red"
              v-on="on"
            >
              {{ icons.information }}
            </v-icon>
          </template>
          <span>{{ $t('deconve.person.noTrainedModels') }}</span>
        </v-tooltip>
      </div>
    </div>
  </v-card>
</template>

<script>
// Copyright (C) 2020 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import { getModels, downloadImage } from '@/api';
import { mdiAccountAlert, mdiCalendarRange, mdiInformation } from '@mdi/js';

import Tag from '@/components/Tag.vue';
import SquareButton from '@/components/SquareButton.vue';

export default {
  name: 'PersonPreview',
  components: {
    Tag,
    SquareButton,
  },
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      profileImage: '',
      isLoadingImage: false,
      isLoadingModelInfo: false,
      isBeingMonitored: true,
      icons: {
        accountAlert: mdiAccountAlert,
        calendarRange: mdiCalendarRange,
        information: mdiInformation,
      },
    };
  },
  computed: {
    ...mapGetters({
      getPerson: 'faceid/getPerson',
      myWorkspace: 'workspace/myWorkspace',
    }),
    person() {
      return this.getPerson(this.id);
    },
    isLoadingPerson() {
      return !this.person;
    },
    profileName() {
      return this.person?.name || '';
    },
    tags() {
      return this.person?.tags || [];
    },
    previewTags() {
      return this.tags.slice(0, 2);
    },
    createdAt() {
      return this.person?.created_at || '';
    },
    isASharedPersonProfile() {
      if (!this.person) {
        return true;
      }

      if (this.person && this.person?.images.length > 0) {
        const { id: currentWorkspaceId } = this.myWorkspace;
        const { id: personWorkspaceId } = this.person.workspace;

        return !this.$can('use', 'com.deconve.hub') && (currentWorkspaceId !== personWorkspaceId);
      }

      return false;
    },
  },
  created() {
    this.isLoadingImage = true;

    this.fetchPerson(this.id).then(() => {
      this.downloadProfileImage();
    }).catch(() => {
      this.isLoadingImage = false;
    });

    this.getModels();
  },

  methods: {
    ...mapActions({
      fetchPerson: 'faceid/fetchPerson',
    }),
    downloadProfileImage() {
      if (this.person) {
        const { profile_image_url: profileImageUrl } = this.person;

        downloadImage(profileImageUrl).then((image) => {
          this.profileImage = image;
          this.isLoadingImage = false;
        });
      }
    },
    seeProfile() {
      if (this.isASharedPersonProfile) {
        return;
      }

      this.$router.push({ name: 'faceid-person', params: { personId: this.id } });
    },
    getModels() {
      this.isLoadingModelInfo = true;

      getModels(this.id).then((models) => {
        this.isBeingMonitored = models && models.length > 0;
        this.isLoadingModelInfo = false;
      });
    },
  },
};
</script>
