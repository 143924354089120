<template>
  <v-container
    class="pa-0"
    style="overflow-y: auto; height: 100%; width: 100%"
    fluid
  >
    <v-data-iterator
      class="d-flex flex-column"
      style="height: 100%"
      :items="people"
      hide-default-footer
      @update:page="onPageNumberUpdated"
    >
      <template v-slot:header>
        <div
          class="pa-3 pb-4 d-flex justify-space-between align-center"
          style="border-bottom: 1px solid;"
          :style="{
            borderColor: $vuetify.theme.themes.light.border,
          }"
        >
          <div
            class="d-flex align-center"
            style="flex: 1"
          >
            <v-menu
              offset-y
              nudge-bottom="12"
              min-width="480px"
            >
              <template v-slot:activator="{attrs}">
                <input-text
                  v-bind="attrs"
                  max-width="440px"
                  right-icon="mdi-camera"
                  left-icon="mdi-magnify"
                  disabled
                  :placeholder="$t('deconve.search')"
                  right-icon-selected
                  @rightIconClicked="close"
                />
              </template>
            </v-menu>
            <v-menu
              :close-on-content-click="false"
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :color="hasFilterChanged? 'primary' : 'neutral'"
                  :class="hasFilterChanged? 'primaryHighlight' : 'white'"
                  :small="$vuetify.breakpoint.mobile"
                  v-bind="attrs"
                  class="mx-2"
                  v-on="on"
                >
                  <v-icon>
                    {{ hasFilterChanged? 'mdi-filter':'mdi-filter-outline' }}
                  </v-icon>
                </v-btn>
              </template>
              <people-filter
                v-model="filterOptions"
                disabled
                :has-changed="hasFilterChanged"
                @clear="clearFilters"
              />
            </v-menu>
          </div>
          <v-btn
            color="primary"
            elevation="0"
            :disabled="!$can('create', 'com.deconve.faceid.person')"
            :small="$vuetify.breakpoint.mobile"
            @click="$router.push({ name: 'faceid-person-register' })"
          >
            <v-icon :left="!$vuetify.breakpoint.mobile">
              {{ icons.addPerson }}
            </v-icon>

            <div v-if="!$vuetify.breakpoint.mobile">
              {{ $t('deconve.person.registerPerson') }}
            </div>
          </v-btn>
        </div>
      </template>

      <template v-slot:default="props">
        <div
          style="flex: 1; overflow-y: auto;"
          :style="{
            backgroundColor: $vuetify.theme.themes.light.background,
          }"
        >
          <v-progress-linear
            v-if="isLoading"
            :indeterminate="true"
          />

          <div
            class="d-flex justify-center align-center pt-3 px-3"
          >
            <person-finder
              :is-loading="isPersonFinderLoading"
              @load-image="handleLoadPersonFinderImage"
            />
          </div>

          <div class="pa-2">
            <div
              v-if="numberOfPeople > 0"
              class="d-flex flex-wrap"
            >
              <v-col
                v-for="item in props.items"
                :key="item.id"
                class="pa-1"
                cols="12"
                sm="6"
                md="4"
                lg="3"
                :data-cy="item.id"
              >
                <person-preview
                  :id="item.id"
                />
              </v-col>
            </div>
            <span
              v-if="isToShowNoPersonIdentifiedMessage"
              class="ml-1 neutralPrimary--text text-subtitle-2 text-sm-subtitle-1 font-weight-bold"
            >
              {{ $t( 'deconve.person.noPersonIdentified') }}
            </span>
          </div>
        </div>
      </template>

      <template v-slot:footer>
        <footer-data-iterator
          :items-per-page-array="itemsPerPageArray"
          :items-per-page="itemsPerPage"
          :page="page"
          :total-data="numberOfPeople"
          @changeItensPerPage="updateItemsPerPage"
          @changePage="onPageNumberUpdated"
        />
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { mdiPlus } from '@mdi/js';

import InputText from '@/components/InputText.vue';
import FooterDataIterator from '@/components/FooterDataIterator.vue';

import {
  booleanToPersonFileStatus,
  booleanToPersonStatus,
  booleanToPersonVideoStatus,
  defaultPeopleFilterOptions,
} from '@/utils/faceidFilters';

import PeopleFilter from './PeopleFilter.vue';
import PersonPreview from './PersonPreview.vue';
import PersonFinder from './PersonFinder.vue';

export default {
  name: 'PeopleFinderDataIterator',
  components: {
    PersonPreview,
    PeopleFilter,
    InputText,
    FooterDataIterator,
    PersonFinder,
  },
  data() {
    return {
      itemsPerPageArray: [4, 8, 12],
      isLoading: false,
      isPersonFinderLoading: false,
      page: 1,
      itemsPerPage: 8,
      filterOptions: defaultPeopleFilterOptions(),
      icons: {
        addPerson: mdiPlus,
      },
    };
  },
  computed: {
    ...mapGetters({
      dataIteratorSettings: 'faceid/getPersonIteratorSettings',
      personFinderImage: 'faceid/personFinderImage',
      personFinderPeople: 'faceid/personFinderPeople',
      personFinderSelectedFaceIndex: 'faceid/personFinderSelectedFaceIndex',
      numberOfPeople: 'faceid/personFinderPeopleTotal',
    }),
    isPersonFinderActivated() {
      return (this.personFinderImage && true);
    },
    people() {
      if (this.numberOfPeople > 0) return this.personFinderPeople;

      // We are using this to avoid to use the data iterator slot for no-data. Using the no-data
      // slot draws the person finder component twice, what creates a flicker in the screen
      return [{}];
    },
    hasFilterChanged() {
      return true;
    },
    isToShowNoPersonIdentifiedMessage() {
      return !this.isPersonFinderLoading && this.numberOfPeople === 0 && (
        this.personFinderSelectedFaceIndex >= 0);
    },
  },
  created() {
    this.getDataIteratorInfo();
  },
  methods: {
    ...mapActions({
      savePersonIteratorSettings: 'faceid/setPersonIteratorSettings',
      fetchPeopleByImage: 'faceid/fetchPeopleByImage',
      removePersonFinderByImage: 'faceid/removePersonFinderByImage',
      setPersonFinderPagination: 'faceid/setPersonFinderPagination',
    }),
    handleLoadPersonFinderImage(file) {
      this.isPersonFinderLoading = true;

      this.fetchPeopleByImage(file).then(() => {
        this.isPersonFinderLoading = false;
        this.page = 1;
        this.onPersonSearchImage();
      }).catch(() => {
        this.isPersonFinderLoading = false;
      });
    },
    clearFilters() {
      this.filterOptions = defaultPeopleFilterOptions();
      this.page = 1;

      this.saveIteratorSettings();
      this.close();
    },
    getDataIteratorInfo() {
      if (this.dataIteratorSettings) {
        const {
          page,
          search,
          sortOrder,
          itemsPerPage,
          sortBy,
          createdAfter,
          createdBefore,
          createdDateOption,
          inTrash,
          hasFiles,
          hasVideos,
          tags,
          noTags,
          sharingStatus,
          unitId,
          unitName,
          noUnit,
          hasFalseNegative,
        } = this.dataIteratorSettings;

        // Data-iterator updates the page value if it is greater than the number of pages availables
        if (page) {
          this.filterOptions = {
            sortOrder,
            sortBy,
            tags,
            noTags,
            sharingStatus,
            status: booleanToPersonStatus(inTrash),
            fileStatus: booleanToPersonFileStatus(hasFiles),
            videoStatus: booleanToPersonVideoStatus(hasVideos),
            unitId,
            unitName,
            noUnit,
            hasFalseNegative,
            createdAfter,
            createdBefore,
            createdDateOption,
            search,
          };

          this.itemsPerPage = itemsPerPage;
          this.page = page;
        }
      }
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      this.page = 1;
      this.definePaginationType();
    },
    definePaginationType() {
      this.onPersonSearchImage();
      this.saveIteratorSettings();
    },
    onPageNumberUpdated(page) {
      this.page = page;
      this.definePaginationType();
    },
    onPersonSearchImage() {
      this.setPersonFinderPagination({ page: this.page, itemsPerPage: this.itemsPerPage });
    },
    saveIteratorSettings() {
      const filterOptions = {
        ...this.filterOptions, page: this.page, itemsPerPage: this.itemsPerPage,
      };

      this.savePersonIteratorSettings(filterOptions);
    },
    close() {
      this.removePersonFinderByImage();
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
  .fixed-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
</style>
